import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from '../styles/page.module.css'

import React from "react"

export default function Policy() {
	return (
		<Layout isNavigationTransparent={false} isHomePage={false}>
			<SEO
				title="Poirot Features"
				description="Instantly find anyone's email address. Backed by industry-leading email lookup & verification technology, Poirot helps your sales and lead generation efforts."
				keywords={[
					`Poirot`,
					`Poirot Features`,
					`Email Lookup Tool`,
					`Email Finder`,
					`Email Checker`,
				]}
			/>
			
			<div className={styles.servicePageWrapper}>
				<div className="sectionWrapper980">

					<h2>POIROT PRIVACY POLICY</h2>

					<p>Intuos, LLC (Intuos or “we”) - developer & operator of poirot.app respects your privacy and we are committed to protecting it through our compliance with this policy. This privacy policy describes the types of information we may collect from you or that you may provide when you visit the website www.poirot.app (our “Website“) (“Service”), and our practices for collecting, using, maintaining, protecting, and disclosing that information. This Privacy Policy applies to Intuos Customers and their use of the Poirot Services as well as visitors to the Website (“Visitors”). For purposes of this privacy policy, Intuos is a data controller of your personal information (as defined below).</p>
					<p>This Privacy Policy does not apply to information collected by Intuos offline or through any other means, including on any other website operated by Intuos or any third party. This Privacy Policy also does not apply to information collected by any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Website, or websites, applications or services that are operated by other parties, including Customers (as defined below).</p>
					<p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website or Services. By accessing or using this Website or the Services, you agree to this privacy policy. This policy may change from time to time. Your continued use of this Website and the Services after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates</p>

 					<h3>Intuos Services</h3>
					<p>The Services allow Intuos business customers (“Customer” or “Customers”) to perform email address lookup and verification and data enrichment on sets of emails. Customers can access these services through manual entry, uploading a csv file or using our APIs.</p>

 					<h3>Information We Collect About You and How We Collect It</h3>
					<p>We collect several types of information from and about Visitors of our Website, including information: by which you may be personally identified, such as name, e-mail address, telephone number, company name, company address and billing information (“personal information“); and/or about your internet connection and usage details.</p>
					<p>We collect this information: Directly from you when you provide it to us; and Automatically as you navigate through the site. Information collected automatically may include usage details (such as your activity on our Website), session data such as IP addresses and web browser software. Intuos does use cookies on the Website or through the Services. In addition, Intuos does respond to Do Not Track signals. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>

					<h3>Information You Provide to Us.</h3>
					<p>The information we collect on or through our Website may include: Information that you voluntarily provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website or subscribing to our service. We may also ask you for information when you report a problem with our Website. Records and copies of your correspondence (including email addresses), if you contact us. Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website. To provide the Services, Intuos receives and stores your personal information (“Customer Data”).</p>
			
					<h3>How we use your information</h3>
					<p>Intuos uses the information that we collect about you or that you provide to us, including any personal information for a variety of purposes including: To provide the Services. To present our Website and its contents to you. To communicate with you about your account and the Services. To provide you with information, products, or services that you request from us. To fulfill any other purpose for which you provide it. To troubleshoot any issues with the Website or Services. To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection. To notify you about changes to our Website or any products or services we offer or provide though it. For any other purpose with your consent. Intuos processes your personal information for the purposes identified above and the legal basis for Intuos processing this information is as follows: (i) in order to provide our Services to you, (ii) for the performance of a contract between you and us, (iii) our legitimate interests, including the proper administration of our website and business, (iv) to protect your vital interests, or (v) your consent, which you may withdraw at any time.</p>

					<h3>When Intuos discloses your information</h3>
					<p>General. Intuos does not provide your personal information or any other information you provide to Intuos to any unaffiliated third parties.</p>
					<p>Third Parties. Intuos may provide your personal information or any other information you provide to Intuos to contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</p>
					<p>Stripe. If you purchase Services through the Website, then your credit card or debit card information is provided directly to our payment service processor, Stripe, to process the payments. The payment information is transmitted via an encrypted connection directly to Stripe. Stripe uses, processes and stores your personal and payment information in accordance with Stripe’s Privacy Policy available at stripe.com/us/privacy. Intuos does not store your payment information. Stripe maintains that it is PCI compliant and you can learn more about Stripe’s security measures available at stripe.com/help/security.</p>
					<p>Legal Requests and Comply with State and Federal Laws. Intuos may release personal information or other information provided by you if required to do so by law, or in the good-faith belief that such action is necessary to comply with state and federal laws or to respond to a court order, subpoena, or search warrant. Intuos also reserves the right to disclose personal information or other information that Intuos believes, in good faith, is appropriate or necessary to enforce Intuos Terms of Service, take precautions against liability, to investigate and defend itself against any third-party claims or allegations, to assist government enforcement agencies, to protect the security or integrity of the Website or Services, and to protect the rights, property, or personal safety of Intuos, its Customers or others.</p>
					<p>In the Event of Merger, Sale, or Bankruptcy. In the event that Intuos is acquired by or merged with a third party entity, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from our users as part of such merger, acquisition, sale, or other change of control. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors rights generally, we may not be able to control how your personal or non-personal information is treated, transferred, or used.</p>

					<h2>Intuos commitment to data security</h2>
					<p>Intuos uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your personal information and Customer End User Data. We cannot, however, ensure or warrant the security of any information you transmit to Intuos through the Website or Services and you do so at your own risk. Once we receive your information, Intuos makes commercially reasonable efforts to ensure the security of your information. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>
					<p>If Intuos learns of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. Intuos may post a notice on its website if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a written notice of a security breach (or to withdraw your consent from receiving electronic notice) you should notify us by sending an email to support@poirot.app.</p>

					<h2>Children’s Privacy</h2>
					<p>Our Website and the Services are not directed to children under 13 years of age. Intuos does not knowingly collect personal information directly from children under 13 years of age without verifiable parental consent. If you are under 13 years of age, then do not use or provide any information on this Website or register on the Website, or use any of the Services. If  we learn we have collected or received personal information from a child under 13, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at support@poirot.app.</p>

					<h2>Data Storage and Retention</h2>
					<p>Your personal information is stored by Intuos on the servers of its cloud-based database storage provider engaged by Intuos, located in the United States. Intuos will retain your information for as long as your account is active or as needed to provide you with the Services. We will delete your information within 30 days of a request by you.</p>
					<p>If you would like more information about Intuos data retention practices with respect to your data or information about deletion of your data or data portability, then please contact Intuos at support@poirot.app.</p>

					<h2>International Transfer of Personal Information</h2>
					<p>Intuos is located in the United States and offers its Services from the United States. If you are located outside the United States, then you understand that your personal information is transferred, processed and stored in the United States. You understand the privacy laws of the United States may not be as protective as the privacy laws of your state, province, country or other governmental jurisdiction. Through your continued use of the Website or the Services you consent to the transfer of your personal information to the United States.</p>

					<h3>Changes to the Privacy Policy</h3>
					<p>This Privacy Policy may be revised periodically and this will be reflected by the “effective date” below. If we make any material changes in the way in which your personal information is collected, used or disclosed, then we will notify you through our website or by email before the changes take effect. If you object to any of the changes, then you can close your account by contacting us as provided below. Please revisit this page to stay aware of any changes. Changes to this privacy policy are effective when they are posted on this page.</p>

					<h3>How you can contact us</h3>
					<p>If you have any questions or concerns about this Privacy Policy, you can contact Intuos at support@poirot.app. In addition, you may also contact Intuos by writing to us at:</p>

					<p>
					Intuos, LLC<br />
					1309 Coffeen Avenue<br />
					Suite 1200<br />
					Sheridan, WY 82801<br />
					</p>
 

<p>Date last modified: This Privacy Policy was last modified on June 01, 2021.</p>
				</div>
			</div>

		</Layout>
	)
}